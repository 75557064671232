import { useToasts } from 'react-toast-notifications'

import { Product, ProductStockTypeEnum } from '@uctypes/api/globalTypes'

const useOutOfStockNotifier = () => {
  const { addToast } = useToasts()

  const isOutOfStockItem = (
    product: Product,
    currentAmountInCart: number,
    subscriptionIsSaved: boolean,
  ): boolean => {

    // @ts-expect-error CartItem has different structure than Product
    const item = product.__typename === 'CartItem' ? product?.product : product
    const itemIsOutOfStock = item.stockType === ProductStockTypeEnum.STOCK && ((currentAmountInCart === item.stockCount) || item.stockCount === 0)

    if (itemIsOutOfStock) {
      addToast(`${item.name} is out of stock.`, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }

    return itemIsOutOfStock
  }

  return isOutOfStockItem
}

export { useOutOfStockNotifier }
