import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { ResponsiveImage, Button, Spacer, Heading, Paragraph } from '@atoms/index'
import { LowStockContainer } from '@client/components/organisms'
import { useConfig } from '@client/contexts/ConfigProvider'
import { DiscoveryVitality, useOutOfStockNotifier } from '@components/index'
import { ProductCardContainer, ProductCardImageContainer, ProductCardContentContainer } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  useUserDetailsQuery, useChangeCartItemQuantityMutation, useAddItemToCartMutation, useRemoveItemFromCartMutation,
  FrozenMealDishListFragment, FrozenMealListFragment,
  useUserCartQuery,
} from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { FrozenPortionSizeEnum, Product, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'

const Container = styled.div`
  ${ProductCardContainer}
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
  .serving-size {
    display: flex;
    flex: 0;
    margin: 0;
  }
`
export interface CraftKidsCardProps {
  craftKidsMeal: FrozenMealDishListFragment
  className?: string
  loading?: boolean
}

interface CraftKidsCardState {
  addToCartInterrupted: boolean
  loading: boolean
  servingSize: FrozenPortionSizeEnum

}

const DEFAULT_STATE: CraftKidsCardState = {
  addToCartInterrupted: false,
  loading: false,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
}

export function CraftKidsCard({ craftKidsMeal, className, loading = false }: CraftKidsCardProps): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [state, setState] = useState<CraftKidsCardState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const pdpLink = `/frozen/craft-kids/${craftKidsMeal.slug}`
  const uiLoading = state.loading || userDetailsLoading || loading
  const product = craftKidsMeal?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const isOutOfStockItem = useOutOfStockNotifier()
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus

  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, { loading: { $set: loading } }))
  }
  const events = useEvents()

  const [addItemToCart] = useAddItemToCartMutation()
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()

  const isAvailable = (): boolean => {
    return craftKidsMeal?.products?.find((dish) => { return dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.availability !== ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY
  }
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  const _handleOnAdd = (): void => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (isAvailable()) {
      _handleAddToCart()
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }
  const _handleOnCenterClick = async (): Promise<void> => {
    if (product.quantityInCart === 0) {
      _handleOnAdd()
    }
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  const _handleOnRemove = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (isAvailable()) {
      setLoading(true)
      const product = craftKidsMeal?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })
      try {
        if (product.quantityInCart === 1) {
          await removeItemFromCart({
            variables: {
              productId: product.id,
            },
          })
        } else {
          await changeCartItemQuantity({
            variables: {
              productId: product.id,
              quantity: product.quantityInCart - 1,
            },
          })
        }

        const logData = {
          itemName: craftKidsMeal?.name,
          itemId: product?.id,
          price: product?.price,
          itemBrand: 'UCOOK',
          itemCategory: craftKidsMeal?.frozenCategories?.map((cat) => cat.id)?.join(', '),
          itemVariant: craftKidsMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
          itemListName: 'Craft Meals',
          quantity: 1,
          itemImage: craftKidsMeal?.coverImage?.location,
          itemStockCount: product?.stockCount > 1 ? 1 : 0,
          itemChef: 'UCOOK',
          isMealkit: 'no',
          itemServingSize: 1,
        }

        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

        events.hasRemovedFromCart(snakedData)

        addToast('Item successfully added to cart', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setLoading(false)

    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }
  const _handleAddToCart = async (): Promise<void> => {

    if (isOutOfStockItem(product as unknown as Product, product.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
      return
    }

    setLoading(true)

    try {
      await addItemToCart({
        variables: { productId: product?.id, quantity: 1 },
      })

      // Analytics

      const logData = {
        itemName: craftKidsMeal?.name,
        itemId: product?.id,
        itemGroupId: craftKidsMeal?.id,
        price: product?.price,
        itemBrand: 'Craft Kids',
        itemCategory: 'Craft Kids',
        itemVariant: craftKidsMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Kids',
        quantity: 1,
        itemImage: craftKidsMeal?.coverImage?.location,
        itemStockCount: product?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakeData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakeData)
      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }

  let notAvailable = false
  let buttonTitle = 'ADD TO CART'
  let craftKidsMealProduct: FrozenMealListFragment

  for (let i = 0; i < craftKidsMeal?.products.length; i++) {
    if (craftKidsMeal?.products[i].frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE) {
      craftKidsMealProduct = craftKidsMeal?.products[i]
    }
  }

  if (craftKidsMealProduct?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
    notAvailable = true
    buttonTitle = 'OUT OF STOCK'
  } else if (craftKidsMealProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
    buttonTitle = 'GET STARTED'
  } else if (craftKidsMealProduct?.availability !== ProductAvailabilityEnum.AVAILABLE) {
    notAvailable = true
    buttonTitle = 'NOT AVAILABLE'
  }

  return (
    <Container className={className}>
      <If condition={craftKidsMeal?.upSellText?.length > 0}>
        <FloatingUpSell text={craftKidsMeal.upSellText} />
      </If>
      <If condition={craftKidsMeal.discoveryHealthyDiningItem}>
        <DiscoveryVitality />
      </If>
      <LinkContainer onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={craftKidsMeal.coverImage} lazy={false} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer className='fluid-grow'>
        <Heading className='card-title' variant='h6'> {craftKidsMeal.name} </Heading>
        <Spacer universal='4px' />
        <Paragraph className='card-sub-title' variant='p2'>by {craftKidsMeal.subTitle} </Paragraph>
        <Spacer universal='4px' />
        <LowStockContainer product={product}>
          <Heading variant='h5'> {`R${product.price}`} </Heading>
        </LowStockContainer>
        <Spacer universal='16px' className='fluid-grow' />
        <Button
          className='add-button'
          title={buttonTitle}
          color='black'
          fullWidth
          loading={loading || state.loading || uiLoading}
          disabled={loading || notAvailable}
          amount={product.quantityInCart}
          onClick={_handleOnCenterClick}
          onLeftIconClick={_handleOnRemove}
          onRightIconClick={_handleOnAdd} />
      </ContentContainer>
    </Container>
  )
}
