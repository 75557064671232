import { ApolloClient, NormalizedCacheObject, ApolloQueryResult } from '@apollo/client'

import { UserCartDocument, UserCartQuery } from '@hooks/api'

import { SiteHelper } from './SiteHelper'

export class MenuHelper {

  private static async getUserCart(client: ApolloClient<NormalizedCacheObject>): Promise<UserCartQuery['currentUser']> {
    const { data }: ApolloQueryResult<UserCartQuery> = await client.query({ query: UserCartDocument })
    return data.currentUser
  }

  // TODO: Move to GQL property in plugin
  public static async shouldSaveMenu(client: ApolloClient<NormalizedCacheObject>): Promise<boolean> {

    const userCart = await this.getUserCart(client)
    // TODO: Remove this function
    const status = SiteHelper.getCheckoutStatus(userCart?.checkoutStatus)
    const hasMenuSaved = userCart.activeMenu.menuIsSaved
    const subscriptionIsSaved = userCart.checkoutStatus.subscriptionIsSaved
    const hasValidDishCount = userCart.activeMenu.dishes.length > 1

    // return !hasMenuSaved && hasValidDishCount && status.hasValidSubscription && !status.hasOnDemandProducts
    return (!hasMenuSaved || !subscriptionIsSaved) && hasValidDishCount && status.hasValidSubscription
  }

}
