import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { MultipleImageContainer, ProductImageInterface } from '@atoms/images/MultipleImageContainer'
import { Heading, Paragraph, Rule, Button, Spacer } from '@atoms/index'
import { LowStockContainer, useOutOfStockNotifier } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import {
  ProductCategoriesContainer, ResponsiveProperty, ResponsivePXValue, ServingSizeEnum,
  SidePagePadding, theme, ZeroSpace,
} from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import {
  useUserDetailsQuery, useGetAppQuery, useUserCartQuery, FrozenTagFragment, useAddItemToCartMutation,
  useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, FrozenMealDishDetailsFragment, FrozenMealListFragment,
} from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import {
  CategoryTag, FieldData, Form, useForm, SelectOption, SectionLoading, ProductCategories, RadioButton, RadioButtonProps,
  FloatingUpSell, convertEnumToNumFRZN,
} from '@molecules/index'
import { FrozenPortionSizeEnum, DeviceTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum, Product } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .input {
    ${ZeroSpace}
  }
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

  .subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .price-header {
    font-weight: 700;
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '16px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const ImageContainer = styled.div`

  ${ProductCategoriesContainer}

  .product-categories {
    ${ResponsivePXValue('max-height', { mobile: '65%' })}
  }

  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
`

const AllergenContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const RuleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
  ${SidePagePadding}
`

const TagsContainer = styled.div`
  display: flex;
  align-self: stretch;
  overflow: auto;

  ${SidePagePadding}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'space-around' })};

  .tag {
    justify-content: flex-start;
    ${ResponsivePXValue('min-width', '116px')}

    img {
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('width', '24px')}
      ${ResponsivePXValue('min-width', '24px')}
    }
  }
`

const InputContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

interface CraftMealDishDetailsFormData {
  quantity: number
  servingSize: FrozenPortionSizeEnum | null
}

export interface CraftMealDishDetailsProps {
  dish: FrozenMealDishDetailsFragment
  allergens: string
  servingSizeOptions: SelectOption[]
  servingSize: string
  servingSizeChange: (servingSize: string) => void
}

interface CraftMealDishDetailsState {
  loading: boolean
  disabled: boolean
  priceDisplay: string
  price: number
  selectedServingSize: FrozenPortionSizeEnum | null
  displayingPriceRange: boolean
  currentAmountInCart: number
  servingSize: FrozenPortionSizeEnum
  currentCartValues: { [k: string]: number }
  isAvailable: boolean
}

const DEFAULT_STATE: CraftMealDishDetailsState = {
  loading: false,
  disabled: true,
  priceDisplay: '',
  price: 0,
  selectedServingSize: null,
  displayingPriceRange: false,
  currentAmountInCart: null,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  currentCartValues: {},
  isAvailable: true,
}

export const CraftMealDishDetails = React.memo(({ dish, allergens, servingSizeOptions, servingSize }: CraftMealDishDetailsProps): JSX.Element => {

  const config = useConfig()
  const [state, setState] = useState<CraftMealDishDetailsState>({ ...DEFAULT_STATE })
  const [quantity, setQuantity] = useState<number>(1)
  const [addItemToCart] = useAddItemToCartMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const theme = useTheme()
  const { addToast } = useToasts()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const events = useEvents()
  const productImagesArray = dish?.productImages as unknown as ProductImageInterface[]
  const product = dish?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const isOutOfStockItem = useOutOfStockNotifier()
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()

  const cartItems = userCartData?.currentUser?.activeCart?.cartItems || []

  const setLoading = (loading: boolean): void => {
    setState(prevState => ({ ...prevState, loading }))
  }

  const getDishAvailability = (): boolean => {

    const unavailable = dish.products.some(
      product => product.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY,
    )

    if (unavailable) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      return false
    }

    return true
  }

  const _handleDefaultAddressCheck = (): boolean => {
    if (!userDetailsData?.currentUser?.hasDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      return false
    } else {
      return true
    }
  }
  const servingSizeNumber = convertEnumToNumFRZN(state.servingSize)

  const _handleAddToCart = async (data: CraftMealDishDetailsFormData) => {

    if (isOutOfStockItem(product as unknown as Product, product.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
      return
    }

    if (!_handleDefaultAddressCheck() || !state.isAvailable) return
    setLoading(true)
    const productId = data.servingSize as string

    try {
      await addItemToCart({
        variables: { productId, quantity },
      })

      const logData = {
        itemName: dish?.name,
        itemId: product?.id,
        itemGroupId: dish?.id,
        price: state.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: servingSizeNumber,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)
      setQuantity(1)
      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }

  const _handleOnAdd = async () => {

    if (isOutOfStockItem(product as unknown as Product, product.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
      return
    }

    if (!_handleDefaultAddressCheck() || !state.isAvailable) return
    setLoading(true)

    try {
      await addItemToCart({
        variables: { productId: product.id, quantity },
      })

      const logData = {
        itemName: dish?.name,
        itemId: product?.id,
        itemGroupId: dish?.id,
        price: state.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: servingSizeNumber,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      setQuantity(1)
      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }
  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSizeOptionsRadio') {
          _setPrice(field.value)
        }
      })
    })
  }
  const _handleOnRemove = async (): Promise<void> => {

    const product = dish?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })
    setState((prevState) => update(prevState, { loading: { $set: true } }))

    try {

      if (state.currentCartValues[product?.id] === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
        })
        const currentValsObject = state.currentCartValues
        if (product.id && currentValsObject[product.id]) {
          delete currentValsObject[product.id]
        }
        setState((prevState) => update(prevState, { currentCartValues: { $set: currentValsObject } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: state.currentCartValues[product?.id] - 1,
          },
        })
      }
      const logData = {
        itemName: dish?.name,
        itemId: product?.id,
        price: state.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: servingSizeNumber,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))

  }

  const _handleOnRightIconClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    _handleOnAdd()
  }
  const _handleOnLeftIconClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    _handleOnRemove()
  }
  const _handleOnCenterClick = async (): Promise<void> => {
    _handleOnAdd()
  }

  const getCraftMealPriceRange = (craftMeal: FrozenMealDishDetailsFragment): string => {

    const getPortionPrice = (frozenPortionSize: FrozenPortionSizeEnum): number => {
      return craftMeal?.products?.find(product => product.frozenPortionSize === frozenPortionSize)?.price || 0
    }

    const lower = getPortionPrice(FrozenPortionSizeEnum.SERVES_ONE)
    const upper = getPortionPrice(FrozenPortionSizeEnum.SERVES_FOUR)
    return `R${lower} - R${upper}`
  }

  const _getDishPriceFromServingSize = (size: string): number => dish?.products?.find(product => product.id === size)?.price || 0

  const _setPrice = (value: string) => {

    const selectedMeal = dish?.products?.find(product => product.id === value)
    let price: number | string = selectedMeal?.price ? selectedMeal.price : dish?.products[0]?.price || 0

    const shouldDisplayPriceRange = price === 0 || selectedMeal?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK || selectedMeal?.stockCount === 0

    if (shouldDisplayPriceRange) {
      price = getCraftMealPriceRange(dish)
    }

    const isMealAvailable = selectedMeal?.stockCount > 0 && getDishAvailability()

    setState((prevState) => update(prevState, {
      servingSize: { $set: selectedMeal?.frozenPortionSize },
      price: { $set: price },
      displayingPriceRange: { $set: shouldDisplayPriceRange },
      isAvailable: { $set: isMealAvailable },
    }))

  }

  const getServingSizeTitle = (product: FrozenMealListFragment): string => ServingSizeEnum[product.frozenPortionSize].toLowerCase() as string

  const getServingSizeOptions = () => {
    const options = dish?.products?.map((product: FrozenMealListFragment) => ({
      title: getServingSizeTitle(product),
      disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
      value: product.id,
    })) || []
    return options.sort((a, b) => a.title > b.title ? 1 : -1)
  }

  const servingSizeOptionsRadio = () => {
    const options = getServingSizeOptions()
    return options.map(option => {
      let amount = 0
      amount = cartItems?.find((item) => item?.product?.id === option.value)?.quantity || 0
      return {
        className: 'size-option',
        value: option.value,
        disabled: option.disabled,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
        count: amount,
      }
    })
  }
  const servingSizeOptionsRadioProps: RadioButtonProps = {
    className: 'serving-size',
    outline: false,
    name: 'servingSizeOptionsRadio',
    options: [...servingSizeOptionsRadio()],
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  const _setDisplayPrice = (): void => {

    const product = dish.products.find(product =>
      cartItems.find(cart => cart.product.id === product.id),
    )

    // console.log('product => ', product, ' dish => ', dish, ' cartItems => ', cartItems)

    const servingSizeValue = product?.id || servingSize
    const price = _getDishPriceFromServingSize(servingSizeValue)
    const stockCount = dish?.products?.find(product => product.id === servingSizeValue)?.stockCount || 0
    const shouldDisplayPriceRange = price === 0 || stockCount === 0
    let priceDisplay = `R${price}`

    if (shouldDisplayPriceRange) {
      priceDisplay = getCraftMealPriceRange(dish)
    }

    setState((prevState) => ({
      ...prevState,
      priceDisplay,
      displayingPriceRange: shouldDisplayPriceRange,
    }))
    form.setFieldsValue({ servingSize: servingSizeValue })
  }

  const getAvailableServing = (): string => {
    const servingSizeOptions = getServingSizeOptions()
    let availableProduct = servingSizeOptions?.find(option => !option.disabled)

    if (!availableProduct) {
      availableProduct = servingSizeOptions[0]
    }

    return availableProduct?.value
  }

  const isCardItemInBasket = () => {

    const product = dish?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
    const productId = product?.id

    const selectedCartItem = cartItems?.find((item) => item?.product?.id === productId)
    const quantity = selectedCartItem?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))
  }

  const setCartAmountsObject = () => {
    for (let i = 0; i < dish?.products.length; i++) {

      const currentServingSizeId = dish?.products[i].id

      for (let j = 0; j < cartItems.length; j++) {
        const productId = cartItems[j]?.product?.id
        const quantity = cartItems[j]?.quantity

        if (currentServingSizeId === productId) {
          const currentCartVals = {
            [productId]: quantity,
          }

          setState((prevState) => update(prevState, {
            currentCartValues: { $merge: currentCartVals },
          }))
        }
      }
    }
  }
  useEffect(() => {
    if (dish && servingSize) {
      _setDisplayPrice()
    }
  }, [dish])

  useEffect(() => {
    if (state.selectedServingSize) {
      const price = _getDishPriceFromServingSize(state.selectedServingSize)
      setState((prevState) => ({ ...prevState, priceDisplay: `R${price}`, price }))
    }
  }, [state.selectedServingSize])

  useEffect(() => {
    const availableServing = getAvailableServing()

    const product = dish.products.find(product =>
      cartItems.find(cart => cart.product.id === product.id),
    )

    form.setFieldsValue({
      servingSizeOptionsRadio: product?.id || availableServing,
    })

    _setPrice(product?.id || availableServing)
  }, [])

  useEffect(() => {
    setCartAmountsObject()
    isCardItemInBasket()
  }, [userCartData, state.servingSize])

  let tag: FrozenTagFragment

  return (
    <Form
      form={form}
      disabled={state.loading || servingSizeOptions?.length === 0}
      onFinish={_handleAddToCart}
      onFieldsChange={_handleChange}>
      <Container>
        <DishContainer>
          <ContentContainer>
            <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='title-header'>
              {dish?.name}
            </Heading>
            <Spacer universal='8px' />
            <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
              {dish?.description}
            </Paragraph>
            <Spacer universal='16px' />
            <LowStockContainer
              displayRule
              product={product}
              flexDirection={isMobile ? 'row' : 'column'}
              alignItems={isMobile ? 'center' : 'flex-start'}>
              <Heading variant='h4' color={theme.colors.greys.darkCodGrey} className='price-header'>
                {`R${state.price}`}
              </Heading>
            </LowStockContainer>
            <Spacer universal='24px' />
            <Choose>
              <When condition={servingSizeOptions?.length === 0}>
                <SectionLoading />
              </When>
              <Otherwise>
                <InputContainer>
                  <RadioButton {...servingSizeOptionsRadioProps} />
                  <Spacer universal='16px' className='fluid-grow' />
                </InputContainer>
                <Spacer universal='16px' />
                <Button
                  className='add-button'
                  title={state.isAvailable ? 'ADD TO CART' : 'OUT OF STOCK'}
                  color='black'
                  fullWidth
                  loading={state.loading}
                  disabled={state.loading || !state.isAvailable}
                  amount={state.currentAmountInCart}
                  amountServingSizeSpecific={state.currentAmountInCart}
                  onClick={_handleOnCenterClick}
                  onLeftIconClick={_handleOnLeftIconClick}
                  onRightIconClick={_handleOnRightIconClick} />
                <Spacer universal='16px' />
              </Otherwise>
            </Choose>
            <AllergenContainer>
              <Paragraph variant='p2' bold color={theme.colors.slates.bitter}>Allergens: {allergens}</Paragraph>
            </AllergenContainer>
          </ContentContainer>
          <ImageContainer id='craftMealDetailsImageContainer'>
            <If condition={dish?.upSellText?.length > 0}>
              <FloatingUpSell text={dish.upSellText} />
            </If>
            <ProductCategories
              product={dish}
              iconSize='large' />
            <MultipleImageContainer images={productImagesArray} />
          </ImageContainer>
        </DishContainer>
        <RuleContainer>
          <Rule color='slate' />
        </RuleContainer>
        <TagsContainer>
          <For each='tag' of={dish?.frozenTags || []}>
            <CategoryTag
              image={tag.icon.desktop}
              title={tag?.title}
              key={tag.id}
              showBackground={false}
              className='tag' />
          </For>
        </TagsContainer>
      </Container >
    </Form>
  )
})

CraftMealDishDetails.displayName = 'CraftMealDishDetails'
